import PeopleSection from "../components/peoplesection";
import BookSection from "../components/booksection";
import Quote from "../components/quote";
import { normalize_graphql_data } from "../components/utils";
import Hero from "../images/livros_hero.jpeg";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import Search from "../components/search";
import { StaticImage } from "gatsby-plugin-image";
const IndexPage = ({ data }) => {
  let random_books = normalize_graphql_data(data.allBeeksJson.nodes);
  let random_people = normalize_graphql_data(data.allPeepsJson.nodes);

  return (
    <Layout pageTitle="Home Page">
      <SEO title="Os livros recomendados pelas pessoas mais bem sucedidas do mundo" />
      <main className="lg:relative">
        <div className="mx-auto  w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-800 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">
                Os Livros indicados pelas pessoas mais
              </span>{" "}
              <span className="block text-blue-800 xl:inline">
                bem sucedidas
              </span>{" "}
              do mundo
            </h1>
            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Basta apenas <span className="font-extrabold">um bom livro</span>{" "}
              para mudar a sua vida
            </p>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <Link
                  to="/top/todos"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-800 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                >
                  Ver os Mais Recomendados
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <StaticImage
            className="absolute inset-0 w-full h-full object-cover"
            src="../images/livros_hero.jpeg"
            alt="Livros famosos empilhados sobre uma mesa"
            placeholder="blurred"
          />
        </div>
      </main>

      <div className="px-10 py-10 ">
        <PeopleSection people={random_people} cta="Pessoas em destaque" />
        <BookSection books={random_books} cta="Livros em destaque" />
        <Quote />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allBeeksJson(limit: 12, sort: { fields: recommended_volume, order: DESC }) {
      nodes {
        ...BeeksJsonFragment
      }
    }
    allPeepsJson(limit: 12, sort: { fields: person_ranking }) {
      nodes {
        ...PeepsJsonFragment
      }
    }
  }
`;

export default IndexPage;
