import React from "react";

import { StaticImage } from "gatsby-plugin-image";
function Quote() {
  let quote = {
    content:
      "Meus filhos terão computadores, sim, mas antes terão livros. Sem livros, sem leitura, os nossos filhos serão incapazes de escrever – inclusive a sua própria história.",
    author: "Bill Gates",
    author_image:
      "https://global-uploads.webflow.com/5e33a765cf951f99dc204f8b/5e4e52530f3ef823f761b95a_bill-gates.jpeg",
    attribute: "Empreendedor",
  };

  return (
    <div className="relative">
      <blockquote className="mt-10 mb-10">
        <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
          <p>&ldquo;{quote.content}&rdquo;</p>
        </div>
        <footer className="mt-8">
          <div className="text-center md:flex md:items-center md:justify-center">
            <div className="md:flex-shrink-0">
              <StaticImage
                className="mx-auto h-10 w-10 rounded-full"
                src="../images/bill-gates_quote.jpeg"
                alt="Bill Gates segurando livros"
                placeholder="blurred"
              />
            </div>
            <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
              <div className="text-base font-medium text-gray-900">
                {quote.author}
              </div>

              <svg
                className="hidden md:block mx-1 h-5 w-5 text-indigo-600"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M11 0h3L9 20H6l5-20z" />
              </svg>

              <div className="text-base font-medium text-gray-500">
                {quote.attribute}
              </div>
            </div>
          </div>
        </footer>
      </blockquote>
    </div>
  );
}

export default Quote;
